/*
 * Global styles are sometimes needed to force styling of child components
 * like the rocket-edit in tags or the slide
 */


/* Settings -> Tags [RocketEdit] */

ul.tags{
    .edit-link{
        color: white !important;
        border-bottom: 1px dotted white !important;
    }
    input {width: 160px;}
}

.inlineEditWrapper {
    display: inline-block;
    width: fit-content !important;
}

.inlineEditForm{
  display: inline-block;
}

/* Settings -> Property -> Schedule [Ng5 Slider] */
#charla-app{
    .ng5-slider{
  
      .ng5-slider-bubble{
        font-size: 1rem;
      }
    
      .ng5-slider-pointer{
        background-color: kt-brand-color();
        width: 15px;
        height: 12px;
        top: -4px;
    
        &:after{
          content: none;
        }
      }
    
      .ng5-slider-selection{
        background-color: kt-brand-color();
      }  
  
      .ng5-slider-tick{
        width: 2px;
      }
  
      .ng5-slider-tick.ng5-slider-selected{
        background-color: kt-brand-color();
      }
  
    }
  }

/* Settings -> Team Members -> Role Input */
.btn-group-toggle{
    label.active{
        color: white !important;
        background-color: kt-brand-color() !important;
    }
}

/* Settings -> Chatflows [NgChips] */

.ng2-tag-input{
    border-bottom: 0px !important;
  }
  
  .ng2-tag-input, .ng2-tag-input--focused{
    border-bottom: 0px !important;
  }
  
  .ng2-tags-container{
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 2px;
  
    tag {
      border-radius: 8px !important;
      &:active{
        background-color: kt-brand-color() !important;
      }
      &:first-child{
        margin-left: 5px;
      }
    }
  
  
  }