

@import "../pages/todo/todo";



/* Increase the font size a bit */
.popover {
    font-size: 1rem;
}

.charla-confirm-popup{
  width: 700px;
}

/* SVG icons in Alerts */
.alert{
  svg{
    margin-right: 5px;
  }
  
  svg g [fill]{
    fill: #ffb822;
  }
}


/* Increase the font size a bit for the ng-select control */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  font-size: 1rem;
  background-color: kt-brand-color();
  color: white;
}



/* ngx-datatable footer */
.ngx-datatable .datatable-footer .datatable-footer-inner{
    flex-direction: row-reverse !important;
    background-color: #f9f9fc;
    height: calc(1.5em + 1.3rem + 2px);
}

/* Secondary font color */
.charla-subtext{
    font-weight: 300;
    font-size: 1rem;
    color:#74788d;
}

/* Remove black border around chrome (@todo still not working) */
option:focus, option:hover{
  outline: none !important;
}

/* Shift the toast message a little bit up */
.toast-bottom-center{
  bottom: 10px !important;
}


/* Save Success checkmark*/
$checkmark-color: #3758ff;
$checkmark-white: #fff;
$checkmark-scope: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
    display: inline-block !important;
    margin-left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: $checkmark-white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $checkmark-color;
    animation: checkmark-fill-animation .4s ease-in-out .4s forwards, checkmark-scale-animation .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $checkmark-color;
  fill: none;
  animation: checkmark-stroke-animation .6s $checkmark-scope forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 4px;
  animation: checkmark-stroke-animation .3s $checkmark-scope .8s forwards;
}

@keyframes checkmark-stroke-animation {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-scale-animation {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkmark-fill-animation {
  100% {
    box-shadow: inset 0px 0px 0px 30px $checkmark-color;
  }
}


@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}



/**
 * Helpers Functions
 */

.ca-flex{
	display: flex;
}

.ca-flex-right{
	justify-content: flex-end;
}

.ca-inline-block{
  display: inline-block;
}

.text-right{
  text-align: right;
}


@media print{
    .no-print, .no-print *
    {
        display: none !important;
    }
}


/**
 * Badges
 **/

 .kt-badge{
  color: white;
  background: kt-brand-color();
}

 .kt-badge[data-index='0']{ background: kt-state-color('warning');  }
 .kt-badge[data-index='1']{ background: kt-state-color('dark');  }
 .kt-badge[data-index='2']{ background: kt-brand-color();  }
 .kt-badge[data-index='3']{ background: kt-state-color('success');  }
 .kt-badge[data-index='4']{ background: kt-state-color('danger');   }   

 /**
  * Pills Selector
  **/

  ul.ca-selector{

    list-style: none;
    display: flex;
    margin: 10px;
    padding-left: 0;

    a{
        margin-right: 8px;
        border-radius: 4px !important;
        font-weight: 500;
        color: #6c7293;
        padding: .75rem 1.1rem;
        transition: all .3s ease;

        &:hover, &.active{
            color: #5d78ff !important;
            background-color: rgba(77,89,149,.06) !important;
            cursor: pointer;
        }
    }

}

/* Subheader */
.kt-subheader--solid .kt-subheader{
  width: 100%
}


/* Feedback Panel */
.feedback-emoji{
  text-align: center;
  margin-bottom: 15px;

  button{

      img{
        width: 60px;
        display: block;
        margin-bottom: 5px;
      }

      &.active, &:hover{
        background-color: rgba(77, 89, 149, 0.1);
      }

    }

}

.tell-us-more{
  text-align: center;
}


 /**
  * Charla Dialog
  **/

.charla-dialog .modal-content{
  padding: 40px;
  font-size: 1.1rem;
}

.info-container{
  .ng-scroll-content{
    max-width: 100%;
  }
}

/* Messages component */

charla-conversations-messages{
  .popover{
    max-width: fit-content;
  }
}


/* Tickets */

.ticket{
  .ng-scroll-content{
      width: 100%;
  }
  
}

/* Date Picker */

ngb-datepicker{
  border: none !important;
  font-family: Inter, Arial, Helvetica, sans-serif;

  .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    height: 2.5rem;
    width: 2.5rem;
  }

  .ngb-dp-header{
    padding-top: 0;
    background-color: white;
    
    ngb-datepicker-navigation-select{
      gap: 15px;
      select{
        font-size: 1rem;
        color: #959cb6;
      }
    }

  }

  .ngb-dp-weekdays{
    background-color: white;
    border: 0;
    .ngb-dp-weekday{
      font-size: 1rem !important;
      color: #959cb6;
    }
  }

  .ngb-dp-month-name{
    font-size: 1rem;
    background-color: white;
    color: #959cb6;
  }

  ngb-datepicker-month{
   
    margin: 10px;

    .ngb-dp-weekday{
        font-style: normal;
        color: black;
    }
  
    .ngb-dp-day {
      margin-bottom: 5px;
    }
  
    .custom-day.range:first {
        border-radius: 0 !important;
    }
  
  
  }

}

.ratings_feed{
  .datatable-header-inner{
      display: none !important;
  }
}


/* Workaround after upgrading to Bootstrap 5 */

.form-text {
  display: block;
  margin-top: .25rem;
}
a{ text-decoration: none;}

.btn-brand{
  background-color: #5d78ff;
  border-color: #5d78ff;
  color: #fff;

  &:hover, &:focus{
    color: #fff;
    background-color: #3758ff;
    border-color: #2a4eff;
  }

  &:disabled{
    color: white;
  }

}

.btn-danger {
  background-color: #f32525;
  border-color: #f32525;
  color: #fff;

  &:hover, &:focus{
    color: #fff;
    background-color: #f31111;
    border-color: #f31111;
  }

  &:disabled{
    color: white;
  }

}

.btn-success {
  background-color: #0abb87;
  border-color: #0abb87;
  color: #fff;

  &:hover, &:focus{
    color: #fff;
    background-color: #0abb87;
    border-color: #0abb87;
  }

  &:disabled{
    color: white;
  }

}

.btn-primary {
  background-color: #5867dd;
  border-color: #5867dd;
  color: #fff;

  &:hover, &:focus{
    color: #fff;
    background-color: #384ad7;
    border-color: #2e40d4;
  }

  &:disabled{
    color: white;
  }

}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active{
  color: #fff ;
  background-color: #5d78ff;
}

/* Global Base Color Styles */

:root {
--base-label-1: #a2a5b9;
--base-label-2: #74788d;
--base-label-3: #595d6e;
--base-label-4: #48465b;

--base-shape-1: #f0f3ff;
--base-shape-2: #e8ecfa;
--base-shape-3: #93a2dd;
--base-shape-4: #646c9a;

--color-brand: #5d78ff;
--color-dark: #282a3c;
--color-primary: #5867dd;

--search-bg: #f2f3f7;

--color-danger: #f32525;
}