@import "assets/vendors/global/vendors.bundle.css";


.kt-header-base-light {
	@import "assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-brand-dark {
	@import "assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-aside-dark {
	@import "assets/sass/global/layout/aside/skins/dark.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}


.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px!important;
	width: 100% !important;
}

.position-static {
	position: static;
}

/**
 ** SVG Handling
 */

button.btn-svg-white svg g [fill], .svg-white svg g [fill] {
    fill: white;
    opacity: 1;
}

.svg-primary svg g [fill]{
	fill: #5d78ff;
}

.svg-grey svg g [fill]{
	fill: #48465b;
}

.svg-danger svg g [fill]{
	fill: var(--color-danger);
}

.svg-invert svg g [fill]{
	fill: white;
}


.svg-fill svg {
	fill: currentColor;
}


.kt-svg-icon-solid g [fill], .kt-svg-icon-solid g rect{
    opacity: 1;
}

// Specific to the profile page 
// @ps For some reason this does not work if added in the relevant components .scss
.kt-avatar__upload:hover{
    .svg-primary svg g [fill]{
        fill: white;
    }
}

// Form validation

.invalid-feedback{
	font-size: 90% !important;
}


/**
 ** Profile 
 */
.dropdown-menu.dropdown-menu-md{
	min-width: 260px;
	width: auto !important;

}

body.kt-aside--minimize{
	.kt-header__topbar-wrapper{
		img{
			max-height: 50px !important;
		}

		.active-status{
			bottom: -6px !important;
    		right: 6px !important;
		}

		.kt-header__topbar-username{
			display: none !important;
		}
	}
}

/**
 ** Stripe 
 */
.StripeElement{
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    margin-top: 5px;
    padding: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--focus{
    border-color: #9aabff;
}

/**
 ** Payment Price 
 */

 
 .charge-price{
	 justify-content: center;
	.charla-paid-desc{
		background-color:  rgb(246,250,255);
		color: black;
		padding: 10px 40px;
		text-align: center;
	}
 }


.charla-paid-desc{
    border-radius: 10px;
    border: 0 !important;
    margin: 0 20px;

    .price{
        font-size: 2rem;
    }
    .metric{
        font-size: 1rem;
    }
    .name{
        font-size: .9rem;
    }

}

/* Message Attachments Modals */

.attachment-modal{
    max-width: none !important; 
	justify-content: center;
	
    .modal-content{
        width: auto;
    }
}

/* Offcanvas ticket creation */
.ticket-create-offcanvas{
	width: 50% !important;
	min-width: 600px;
}