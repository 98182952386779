//
// Chat
//

charla-conversations-visitors{

    .kt-portlet__body {
        padding-top: 10px !important;
    }
 
    input{
        height: calc(1.4em + 1.0rem + 2px) !important;
    }

    .visitors-container{
        height: calc(100vh - 155px);
    }

    .kt-widget.kt-widget--users .kt-widget__item{

        margin: 1.5rem 0 1.5rem 0;
        cursor: pointer;
    
        &:hover, &.selected{
          background-color: kt-state-color(dark, base);
          .kt-widget__desc, .kt-widget__date, .kt-widget__username {
            color: kt-state-color(dark, inverse) !important;
          }
        }
    }
}


.kt-chat {
    .kt-portlet__head {        
        align-items: center;
    }
    
    .kt-chat__head {       
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-grow: 1;

        .kt-chat__label {
            .kt-chat__title {
                font-size: 1.1rem;
                color: kt-base-color(label, 4);
                font-weight: 600;
                margin: 0;
                padding: 0;            
    
                &:hover {
                    color: kt-brand-color();
                    transition: color 0.3s ease;
                }
            }
    
            .kt-chat__status {
                color: kt-base-color(label, 2);
                font-size: 0.9rem;
                font-weight: 500;
                display: block;
            }            
        }        

        .kt-chat__left {
            flex-grow: 1;
            text-align: left;
        }

        .kt-chat__right {
            flex-grow: 1;
            text-align: right;
        }

        .kt-chat__center {
            flex-grow: 1;
            text-align: center;
        }
    }

    .kt-chat__input {  
        .kt-chat__editor {
            textarea {
                width: 100%;
                border: 0 !important;
                outline: none !important;
                background-color: transparent !important;

                @include kt-input-placeholder(kt-base-color(label, 1));
                color: black;
                @include kt-tablet-and-mobile {
                    font-size: 16px;
                }
            }
        }      
        
        .kt-chat__toolbar {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .kt_chat__tools {
                a {
                    i {
                        margin-right: 1.1rem;                
                        color: #cdd1e9;
                        font-size: 1.4rem; 

                        &:hover {
                            color: kt-brand-color();
                            transition: color 0.3s ease;
                        }
                    }                    
                }                
            }
        }
    }
}

.kt-chat__messages {

    .message-group{
        text-align: center;
        > div {
            background-color: kt-state-color(dark, base);
            padding: 2px 12px;
            border-radius: 10px;
            color: white;
            display: inline-block;
            margin-bottom: 1rem;
        }
    }

    .message-activity{
        text-align: center;
        padding: 0.5rem;
        > div {
            display: inline-block;
            color: white;
            padding: 2px 12px;
            background-color: kt-brand-color();
        }
    }


    .kt-chat__message {
        margin-bottom: 1rem;

        .kt-chat__user {
            .kt-chat__username {
                padding: 0 0.4rem;
                font-size: 1.1rem;
                color: kt-base-color(label, 4);
                font-weight: 500;

                &:hover {
                    color: kt-brand-color();
                    transition: color 0.3s ease;
                }
            }

            .kt-chat__datetime {
                color: kt-base-color(label, 2);
                font-size: 0.9rem;
                font-weight: 400;
            }

            .kt-media--sm{
                margin: 0 5px;
                vertical-align: middle;
            }

        }

        .kt-chat__text {
            display: inline-block;
            padding: 1.2rem 1.7rem;
            margin-top: 0.8rem;                
            color: #6c7293;
            font-weight: 500; 
            font-size: 1.1rem;                               

            position: relative;
            padding: 0.5rem 3rem 1.3rem 1rem !important;
            max-width: 80%;
            min-width: 80px; /* at least accomodate for the datetime + stick nark */
            word-break: break-word;

            > span {
                font-size: 80%;
                position: absolute;
                bottom: 1px;
                right: 5px;
                .datetime{
                    opacity: .7;
                }
            }

            b {
                color: kt-base-color(label, 3);
                font-weight: 0; 
            }

            .read_indicator svg, .failed_indicator svg{
                width: 24px;
                height: 24px;
            }
            
            .failed_indicator svg g [fill]{
                fill: #f0af0f;
            }
            
            .failed_indicator svg {
                vertical-align: bottom;
            }
            
            > a.kt-link{
                color: #48465b !important;
                &:hover{
                    &::after{
                        border-bottom: 1px solid #48465b;
                    }
                }
            }

            @include kt-rounded {
                border-radius: $kt-border-radius;
            }    
        }

        &.kt-chat__message--right {
            text-align: right;
            padding-right: 15px;

            .kt-chat__text {
                text-align: right;
                padding: 1.1rem 1.6rem 1.1rem 3.5rem;
            }
        }            
    }
    
    &.kt-chat__messages--solid {
        width: 100%;             
         
        .kt-chat__message {
            padding: 1.3rem 1.7rem;
            margin: 1.5rem 0;                
            display: inline-block;

            &:first-child {
                margin-top: 0;
            }

            @include kt-rounded {
                border-radius: $kt-border-radius;
            }   

            .kt-chat__text {
                padding: 0;
                margin-top: 0.5rem; 
                background: none !important;                  
            }

            &.kt-chat__message--right {
                float: right;
            } 
            
            @each $name, $color in $kt-state-colors {
                &.kt-chat__message--#{$name} {                        
                    background: rgba(kt-get($color, base), 0.1);                      
                }
            } 
        }
    }
}



@include kt-tablet-and-mobile {
    .kt-chat {
        .kt-chat__messages {
            .kt-chat__message {
                margin-bottom: 0.75rem;

                .kt-chat__user {
                    .kt-chat__username {
                        padding: 0 0.25rem;
                        font-size: 1rem;
                    }
                }
        
                .kt-chat__text {
                    display: inline-block;
                    padding: 0.75rem 1rem;
                    margin-top: 0.5rem;
                }

                &.kt-chat__message--right {
                    .kt-chat__text {
                        padding: 0.75rem 1rem;
                    }
                }
            } 
        }

        .kt-chat__input {              
            .kt-chat__toolbar {
                margin-top: 0.75rem;
                .kt_chat__tools {
                    a {
                        i {
                            margin-right: 0.75rem;
                            font-size: 1.3rem; 
                        }                    
                    }                
                }
            }
        }
    }
}
