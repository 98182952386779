.sessions-history{
    
    .session{
        display: flex;
        align-items: center;
        padding: 1.1rem 1.5rem;
        border-bottom: 1px solid kt-base-color(grey, 1);
        transition: background-color 0.3s ease;

        button {padding: .65rem}

        .title{
            font-size: 1rem;
            font-weight: 400;
            padding-right: 10px;
            flex-grow: 1;
            a{ 
                font-weight: 500;
                transition: color .3s ease;
                color: kt-base-color(label, 3);
                &:hover{ 
                    color: kt-brand-color();
                    transition: color 0.3s ease;
                }
            }
            span{ display: block;} 
        }

        .link{

        }

        .email{

        }

    }
}