//
// Header Menu
//

@include kt-desktop {
	.kt-header-menu-wrapper {
		body:not(.kt-aside--enabled) & {
			display: flex;
			flex-direction: row;
			align-items: stretch;

			.kt-header-logo {
				padding: 0 kt-get($kt-page-padding, desktop);
				display: flex;
				align-items: center;
				margin-right: 25px;
			}
		}
	}

}

@include kt-tablet-and-mobile {
	.kt-header-menu-wrapper {

		.kt-header-logo {
			display: none;
		}
	}
}
