/*
bootstrap table theme
*/

$page-size: 2.25rem;

// Pagination colors
$page-default: kt-base-color(shape, 1);
$page-default-font: kt-base-color(shape, 3);
$page-hover: kt-state-color(brand);
$page-hover-font: kt-state-color(brand, inverse);
$page-active: kt-state-color(brand);
$page-active-font: kt-state-color(brand, inverse);

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 13px;
    .datatable-header {
      height: unset !important;
      .datatable-header-cell {
        vertical-align: bottom;
        padding: 0.75rem;
        &.sort-active{
            color: kt-state-color(brand);
        }

        .datatable-header-cell-label {
          line-height: 24px;
          font-weight: bold;
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        vertical-align: top;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        &.datatable-row-even {
          background-color: rgba(0, 0, 0, 0.01);
        }
        &.active {
          background-color: #1483ff;
          color: #fff;
        }
        .datatable-body-cell {
          padding: 0.75rem;
          text-align: left;
          vertical-align: top;
        }
      }
      .empty-row {
        $alert-padding-y: 0.75rem !default;
        $alert-padding-x: 1.25rem !default;
        position: relative;
        padding: $alert-padding-y $alert-padding-x;
        margin-bottom: 0;
      }
    }

    .datatable-footer {

      margin-top: -1px;
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
      }
      .datatable-pager {
        margin: 0 10px;
        vertical-align: top;
        ul {
        margin: 0;
        padding: 0;
        display: flex;
          li {
            padding: 0;
            margin-right: 5px;
            display: inline-block;

            a {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: $page-size;
                min-width: $page-size;	
                padding: 0.5rem;
                border-radius: 3px;
                color: $page-default-font;
                
                @include kt-transition();
                @include kt-not-rounded {
                    border-radius: 0;
                }
    
                position: relative;
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 500;
    
                > i {
                    font-size: 0.6rem;
                    text-align: center;
                    display: inline-block;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child, &:last-child, &:nth-child(2), &:nth-last-child(2){
                a{
                    background: $page-default;
                    font-weight: 600;
                    
                    &:hover {
                        background: $page-hover;
                        color: $page-hover-font;
                    }
                }
            }



            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                    background: #5d78ff;
                    color:#fff;
                }
              }
            }
          }
        }

        .datatable-icon-left,
        .datatable-icon-skip,
        .datatable-icon-right,
        .datatable-icon-prev {
            font-size: 18px;
        }
      }
    }
    // Summary row styles
    .datatable-summary-row {
      .datatable-body-row {
        .datatable-body-cell {
          font-weight: bold;
        }
      }
    }
  }